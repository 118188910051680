import React, { useEffect, useState } from 'react';
import Sidebar from '../../component/Sidebar';
import Topbar from '../../component/Topbar';
import { useTranslation } from 'react-i18next';
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';

// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';
import '../../css/uicons-bold-rounded.css';
import { useDispatch, useSelector } from 'react-redux';
import { blogCreateApi, blogGetByIdApi, blogUpdateApi } from '../../redux/ApiActionCreator';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddBlog = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [content, setContent] = useState('')
    const [imageByteCode, setImageByteCode] = useState('');
    const [imageName, setImageName] = useState('');
    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState('')
    const [loading, setLoading] = useState(false)
    const [metaDiscription, setMetaDiscription] = useState('')
    const [metaKeyWord, setMetaKeyWord] = useState('')
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const blogFlagData = useSelector((state) => state?.apiReducer?.blogFlagData)
    const blogGetByIdData = useSelector((state) => state?.apiReducer?.blogGetByIdData)


    //GET BLOG DETAIL API CALL
    useEffect(() => {
        if (blogFlagData?.blogType != 'create') {
            dispatch(blogGetByIdApi(token, blogFlagData?.blogId))
        }

    }, [])
    useEffect(() => {
        if (blogFlagData?.blogType != 'create') {
            setTitle(blogGetByIdData?.Title)
            setImageName(blogGetByIdData?.OldFileName)
            setContent(blogGetByIdData?.Description)
            setMetaDiscription(blogGetByIdData?.MetaDescription)
            setMetaKeyWord(blogGetByIdData?.MetaKeywords)
        }

    }, [])


    const config = {
        toolbarAdaptive: false, // Disable adaptive toolbar    
        buttons: 'bold,italic,underline,|,ul,ol,|,align,|,fontsize,brush,|,paragraph', // Specify which buttons to show    
        disablePlugins: 'about,resize,video,table', // Specify which plugins to disable    
        uploader: {
            insertImageAsBase64URI: true, // This enables uploading images as base64 directly
        },


    };



    //BLOG IMAGE UPLOAD
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (!allowedTypes.includes(file?.type)) {
            alert('Please upload a JPG, JPEG or PNG File');
            event.target.value = null;
            event.target.dispatchEvent(new Event('input'));
            return; // Exit the function if file type is not allowed
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                const { width, height } = image;
                if (width !== 750 || height !== 400) {
                    alert('Image must be 750px width and 400px height.');
                    event.target.value = null;
                    event.target.dispatchEvent(new Event('input'));
                    return; // Exit the function if image dimensions are not correct
                }
                const byteCode = reader.result;
                setImageByteCode(byteCode);
                setImageName(file.name);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const validateBlogTitle = (value) => {
        if (value.length === 0) {
            setTitleError('Please Enter Blog Title');
        } else {
            setTitleError('');
        }
    };

    //CREATE AND UPDATE BLOG API CALL
    const createBlog = () => {
        let validate = false
        if (title == '' || title == null) {
            validate = true
            setTitleError('Please Enter Blog Title');
        } else {
            setTitleError('');
        }

        if (validate == false) {
            setLoading(true)

            if (blogFlagData?.blogType == 'create') {
                const params = {
                    "Title": title == '' ? null : title,
                    "Description": content == '' ? null : content,
                    "BlogImageBase64": imageByteCode == "" ? null : imageByteCode,
                    "BlogImageFileNameWithExtension": imageName == "" ? null : imageName,
                    "MetaKeywords": metaKeyWord == "" ? null : metaKeyWord,
                    "MetaDescription": metaDiscription == "" ? null : metaDiscription,
                }
                dispatch(blogCreateApi(token, params))
                    .then((response) => {
                        toast.success("Record Saved Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                        setTimeout(() => {
                            setLoading(false)
                            navigate('/CMS')
                        }, 1000);
                    })
                    .catch((error) => {


                        setLoading(false)
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    });
            } else {
                const params = {
                    "Id": blogFlagData?.blogId,
                    "Title": title == '' ? null : title,
                    "Description": content == '' ? null : content,
                    "BlogImageBase64": imageByteCode == "" ? null : imageByteCode,
                    "BlogImageFileNameWithExtension": imageName,
                    "MetaKeywords": metaKeyWord == "" ? null : metaKeyWord,
                    "MetaDescription": metaDiscription == "" ? null : metaDiscription,
                }

                dispatch(blogUpdateApi(token, params))
                    .then((response) => {
                        toast.success("Record Saved Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                        setTimeout(() => {
                            setLoading(false)
                            navigate('/CMS')
                        }, 1000);
                    })
                    .catch((error) => {
                        setLoading(false)

                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    });
            }



        }
    }
    return (
        <div className='App'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <Topbar />
            <Sidebar message={'CMS'} />

            <section class="content">
                <div class="block-header">
                    <h2>{t('cms')}</h2>
                </div>
                <div class="row clearfix">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">


                        <div class="tab_content_block">

                            <>
                                <div class="main_class">

                                    <h3 class="main_title">Blog</h3>
                                    <div class="form_block">
                                        <div class="row clearfix">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="form-line">
                                                        <input style={{ height: 60.50 }} title={t('blogTitle')} type="text" class="form-control" maxLength={100} placeholder={t('blogTitle')} value={title} onChange={(e) => { setTitle(e.target.value); validateBlogTitle(e.target.value) }} />
                                                        {
                                                            titleError && (
                                                                <span class="validation_notes">{titleError}</span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="form-line">
                                                        <div class="custom-file" >
                                                            <input style={{ height: 70 }} title="Select Your Blog Image" type="file" class="custom-file-input" id="customFile" onChange={handleImageChange} />

                                                            <label class="custom-file-label" for="customFile" style={{ fontSize: 16 }}>{imageName ? imageName : "Select Your Blog Image"}</label>
                                                        </div>
                                                        <p class="logo_note">Note : Image Must be 750px X 400px</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="form-line">
                                                        <textarea
                                                            rows="4"
                                                            title="Meta Description"
                                                            style={{ height: 80 }}
                                                            maxLength={310}
                                                            className="form-control no-resize"
                                                            value={metaDiscription}
                                                            placeholder={'Meta Description'}
                                                            onChange={(e) => { setMetaDiscription(e.target.value) }}

                                                        ></textarea>


                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="form-line">
                                                        <textarea
                                                            rows="4"
                                                            title="Meta Keyword"
                                                            style={{ height: 80 }}
                                                            maxLength={510}
                                                            className="form-control no-resize"
                                                            value={metaKeyWord}
                                                            placeholder={'Meta Keyword'}
                                                            onChange={(e) => { setMetaKeyWord(e.target.value) }}

                                                        ></textarea>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <JoditEditor
                                        config={config}
                                        value={content}
                                        maxLength={3000}
                                        onBlur={newContent => { setContent(newContent) }}
                                        onChange={(newContent) => console.log(newContent)}
                                    />

                                    {/* {
                                        decError && (
                                            <span class="validation_notes">{decError}</span>
                                        )
                                    } */}
                                    <div class="button_block">
                                        <a onClick={() => createBlog()} class="links">{t('save')}</a>

                                    </div>

                                </div>
                                <div>

                                </div>
                            </>

                        </div>


                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    )
}

export default AddBlog